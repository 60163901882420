import React from "react";
import styles from "./hero.module.scss";
import hero from "../images/index_banner-1.jpg";

import NewsletterSignUp from "./newsletterSignUp";

const Hero = () => {
  return (
    <>
      <div className={styles.Hero}>
        <div className={styles.img_container}>
          <img src={hero} alt="" />
        </div>

        <div className={styles.newsletter_container}>
          <NewsletterSignUp
            data={{
              inputPlaceholder: "email address",
              submitButton: "STAY INFORMED",
              label: "Get the Newsletter",
            }}
          />
        </div>
      </div>
      <div className={styles.color_div_lg} />
    </>
  );
};

export default Hero;
