import React from "react";
import styles from "./pages.module.scss";

import Hero from "../components/hero";
import ContentLeft from "../components/contentLeft";
import ContentRight from "../components/contentRight";
import { index } from "../utils/content";

export default () => (
  <div className={styles.Page}>
    <Hero />
    {index.map((section, index) => {
      if (index % 2 === 0) {
        return (
          <ContentLeft content={section} page="index" key={section.title} />
        );
      } else {
        return (
          <ContentRight content={section} page="index" key={section.title} />
        );
      }
    })}
  </div>
);
